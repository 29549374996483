@use "../../partials/mixins" as m;

.projects {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include m.section-dimensions;
  @include m.color-transition;
  overflow: hidden;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3rem;
}

.projects-light {
  @include m.light-theme-template;
}

.projects-dark {
  @include m.dark-theme-template;
}
