.projects {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(2, minmax(auto, 576px));
  align-items: center;
  gap: 4.2vw;
  width: 80%;

  margin: 3.5rem auto;
}

@media (max-width: 1200px) and (min-height: 850px) {
  .projects {
    width: 85%;
  }
}

@media (max-width: 768px) and (min-height: 850px) {
  .projects {
    grid-template-columns: minmax(auto, 310px);

    margin: 2.5rem auto;
  }
}

@media (max-width: 768px) and (max-height: 850px) {
  .projects {
    grid-template-columns: minmax(30vh, 200px);
    gap: 1rem;
    margin: 2.5rem auto;
  }
}

@media (min-width: 768px) and (max-height: 850px) {
  .projects {
    grid-template-columns: repeat(2, clamp(250px, 58vh, 300px));
    gap: min(4.2vw, 4.2vh);
  }
}

@media (min-width: 950px) and (max-height: 850px) {
  .projects {
    grid-template-columns: repeat(2, clamp(250px, 58vh, 400px));
    gap: min(4.2vw, 4.2vh);
  }
}

@media (min-width: 1275px) and (max-height: 850px) {
  .projects {
    grid-template-columns: repeat(2, clamp(250px, 58vh, 576px));
    gap: min(4.2vw, 4.2vh);
  }
}
