@use "../../partials/mixins" as m;

.skills {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  @include m.section-dimensions;
  @include m.color-transition;
  overflow: hidden;
}

.skills-light {
  @include m.light-theme-template;
}

.skills-dark {
  @include m.dark-theme-template;
}

@media (max-height: 850px) {
  .skills {
    gap: 2rem;
  }
}
