@use "../../partials/variables" as v;

.button-link-resizeable {
  width: 100%;
  height: 100%;
  max-width: 12rem;
  max-height: 3.5rem;
}

.button-container {
  position: relative;
  max-width: 12rem;
  max-height: 3.5rem;
  font-family: "Roboto";
  font-size: 1.2rem;
  overflow: hidden;
  box-sizing: content-box;
  opacity: 1;

  transition: border v.$theme-toggle-transition-duration ease,
    background-color v.$theme-toggle-transition-duration ease,
    opacity 800ms linear, transform 800ms ease;
}

.button-container-before-up {
  opacity: 0;
  transform: translateY(-10rem);
}

.button-container-before-down {
  opacity: 0;
  transform: translateY(10rem);
}

.button-container-before-left {
  opacity: 0;
  transform: translateX(-10rem);
}

.button-container-before-right {
  opacity: 0;
  transform: translateX(10rem);
}

.button-container-before-stationary {
  opacity: 0;
  transform: translate(0, 0);
}

.button-container-after {
  opacity: 1;
  transform: translate(0, 0);
}

.button-container-dark {
  background-color: v.$primary-color-dark;
  color: v.$accent-color;
  border: 2px solid v.$accent-color;
}

.button-container-light {
  background-color: v.$primary-color-light;
  color: v.$secondary-color-four;
  border: 2px solid v.$secondary-color-four;
}

.button-container-resizeable {
  width: 100%;
  height: 100%;
  font-size: max(0.9rem, min(2vw, 1.2rem));
  opacity: 1;
  transform: translateX(0);
  background-color: transparent;

  transition: border v.$theme-toggle-transition-duration ease;
}

.button-container-fixed {
  width: 12rem;
  height: 3.5rem;
}

.button {
  position: absolute;
  width: 110%;
  height: 150%;
  top: 50%;
  left: 50%;
  transform: translate(calc(-50% + 1px), calc(-50% + 1px));

  mask: url(https://raw.githubusercontent.com/robin-dela/css-mask-animation/master/img/urban-sprite.png);
  -webkit-mask: url(https://raw.githubusercontent.com/robin-dela/css-mask-animation/master/img/urban-sprite.png);
  mask-size: 2900% 100%;
  -webkit-mask-size: 3000% 100%;
  mask-position: 100% 0;
  -webkit-mask-position: 100% 0;
  border: none;
  color: v.$primary-color-dark;
  font-family: inherit;

  transition: background-color v.$theme-toggle-transition-duration ease;
}

.button-dark {
  background-color: v.$accent-color;
}

.button-light {
  background-color: v.$primary-color-dark-shade;
  background-color: v.$secondary-color-four;
  color: white;
}

.button-text {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  user-select: none;

  transition: color v.$theme-toggle-transition-duration ease;
}

.button-animated {
  animation: clear 0.6s steps(29) forwards;
}

.button-resizeable {
  font-size: max(0.9rem, min(2vw, 1.2rem));
}

.button-fixed {
  font-size: 1.2rem;
}

.button-container-hovered {
  cursor: pointer;
  .button {
    cursor: pointer;

    animation: fillUp 0.6s steps(29) forwards;
  }
}

@keyframes fillUp {
  from {
    mask-position: 100% 0;
    -webkit-mask-position: 100% 0;
  }
  to {
    mask-position: 0 0;
    -webkit-mask-position: 0 0;
  }
}

@keyframes clear {
  from {
    mask-position: 0 0;
    -webkit-mask-position: 0 0;
  }
  to {
    mask-position: 100% 0;
    -webkit-mask-position: 100% 0;
  }
}

@media (max-width: 768px) and (max-height: 850px) {
  .button {
    transform: translate(-50%, -50%);
    max-width: 10rem;
    max-height: 3rem;
    font-size: 1rem;
  }
  .button-container {
    max-width: 10rem;
    max-height: 3rem;
    font-size: 1rem;
  }
  .button-container-resizeable {
    font-size: 0.6rem;
  }
  .button-resizeable {
    font-size: 0.6rem;
  }
}

@media (min-width: 768px) {
  .button-container-resizeable {
    font-size: max(0.6rem, min(1.5vw, 1.2rem));
  }
  .button-resizeable {
    font-size: max(0.6rem, min(1.5vw, 1.2rem));
  }
}

@media (min-width: 768px) and (max-height: 850px) {
  .button-container-resizeable {
    font-size: max(0.6rem, min(1.2vw, 1rem));
  }
  .button-resizeable {
    font-size: max(0.6rem, min(1.2vw, 1rem));
  }
}

@media (min-width: 768px) and (max-height: 750px) {
  .button-container-resizeable {
    font-size: 0.8rem;
  }
  .button-resizeable {
    font-size: 0.8rem;
  }
}
