@use "../../partials/variables" as v;

.project {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  width: 100%;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.8);
  font-family: "Roboto";
  user-select: none;
  transform: translateY(5rem);

  transition: opacity 800ms ease, transform 800ms ease;

  &:hover,
  &:focus,
  &:active {
    .inner-content {
      background-color: rgba(v.$primary-color-dark-shade, 0.95);
      pointer-events: all;

      > * {
        opacity: 1;
      }
    }
  }
}

.project-before {
  opacity: 0;
}

.project-after {
  opacity: 1;
  transform: translateY(0);
}

.inner-content {
  position: absolute;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  background-color: rgba(v.$primary-color-dark-shade, 0);
  pointer-events: none;

  transition: background-color 500ms ease;

  > * {
    opacity: 0;
    // This will-change property prevents some strange visual effects in Chrome and Firefox after the opacity transition is completed.
    will-change: opacity;

    transition: opacity 500ms ease;
  }
}

.inner-content-dark {
  color: v.$secondary-color-two;
}

.inner-content-light {
  color: v.$secondary-color-three;
}

.title {
  font-size: max(1.4rem, min(2.25vw, 2.25rem));
  text-align: center;
}

.github-link {
  width: 17.5%;
  height: 17.5%;
}

.button-wrapper {
  width: 32%;
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -4px;
}

.project-image {
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) and (max-height: 850px) {
  .title {
    font-size: 0.9rem;
  }
  .inner-content {
    gap: 0.5rem;
  }
}

@media (min-width: 950px) and (max-height: 850px) {
  .title {
    font-size: 1.4rem;
  }
  .inner-content {
    gap: 0.5rem;
  }
}

@media (min-width: 1275px) and (max-height: 850px) {
  .title {
    font-size: 1.8rem;
  }
  .inner-content {
    gap: 0.5rem;
  }
}

@media (min-width: 768px) and (max-height: 750px) {
  .title {
    font-size: 1.1rem;
  }
  .inner-content {
    gap: 0.5rem;
  }
}

@media (max-width: 420px) and (min-height: 850px) {
  .title {
    font-size: 1.1rem;
  }
  .inner-content {
    gap: 0.5rem;
  }
}
