@use "../../partials/variables" as v;

.label-field-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.label {
  display: block;
  font-size: 1.3rem;
  margin: 0.5rem 0;
}

.field {
  height: 3rem;
  width: 100%;
  padding: 13px 20px;
  border: none;
  outline: none;
  font-family: "Roboto";
  font-size: 1rem;
  resize: none;

  &:focus::placeholder {
    color: transparent;
  }
}

.field-dark {
  background-color: v.$primary-color-dark-tint;
  color: v.$secondary-color-two;
  caret-color: v.$primary-color-light;

  &::placeholder {
    color: v.$primary-color-light-shade;
  }

  &::selection {
    color: v.$secondary-color-two;
  }
}

.field-light {
  background-color: v.$primary-color-light-tint;
  color: v.$primary-color-dark-shade;
  caret-color: v.$primary-color-dark-tint;

  &::placeholder {
    color: v.$primary-color-dark-tint;
  }

  &::selection {
    color: v.$primary-color-dark-tint;
  }
}

.field-large {
  height: 250px;
}

@media (max-height: 850px) {
  .label {
    font-size: 1.2rem;
  }
  .field {
    height: 2rem;
    padding: 8px 13px;
    font-size: 0.8rem;
  }
  .field-large {
    height: 200px;
  }
}

@media (max-height: 700px) {
  .label {
    font-size: 1rem;
  }
  .field {
    height: 2rem;
    padding: 8px 13px;
    font-size: 0.8rem;
  }
  .field-large {
    height: 100px;
  }
}
