.subheading {
  font-size: 3rem;
  text-align: center;

  transition: opacity 800ms linear;
}

.subheading-before {
  opacity: 0;
}

.subheading-after {
  opacity: 1;
}

@media (max-width: 1200px) {
  .subheading {
    font-size: 2.25rem;
  }
}

@media (max-width: 768px), (max-height: 850px) {
  .subheading {
    font-size: 1.5rem;
  }
}
