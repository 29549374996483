@use "../../partials/variables" as v;

.loading-triangle-svg {
  position: absolute;
  stroke: v.$secondary-color-three;
  stroke-width: 3px;
  stroke: transparent;
}

.loading-triangle-svg-colored {
  stroke: v.$secondary-color-three;
}

.loading-triangle-svg-animated {
  .stroke1 {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation: outlineAnimation 1200ms ease forwards;
    animation-delay: 300ms;
  }
  .stroke2 {
    stroke-dasharray: 900;
    stroke-dashoffset: 900;
    animation: outlineAnimation 1200ms ease forwards;
    animation-delay: 1400ms;
  }
}

@keyframes outlineAnimation {
  to {
    stroke-dashoffset: 0;
  }
}

@media (max-width: 768px) {
  .loading-triangle-svg {
    transform: scale(0.5);
  }
}
