.skill-box-grouping {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 620px;
  gap: 1.5rem;
}

.skill-boxes {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

@media (max-width: 768px) {
  .skill-box-grouping {
    width: 85%;
  }
}
