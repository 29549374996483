@use "../../partials/variables" as v;
@use "../../partials/mixins" as m;

.paragraph {
  text-align: center;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  @include m.color-transition;
  opacity: 1;

  transition: opacity 800ms linear, transform 800ms ease,
    color v.$theme-toggle-transition-duration ease;
}

.paragraph-before-up {
  opacity: 0;
  transform: translateY(-10rem);
}

.paragraph-before-down {
  opacity: 0;
  transform: translateY(10rem);
}

.paragraph-before-left {
  opacity: 0;
  transform: translateX(-10rem);
}

.paragraph-before-right {
  opacity: 0;
  transform: translateX(10rem);
}

.paragraph-after {
  opacity: 1;
  transform: translate(0, 0);
}

.paragraph-dark {
  color: v.$secondary-color-two;
}

.paragraph-light {
  color: v.$primary-color-dark-shade;
}

@media (max-width: 768px), (max-height: 850px) {
  .paragraph {
    font-size: 1.25rem;
  }
}

@media (max-width: 768px), (max-height: 700px) {
  .paragraph {
    font-size: 1rem;
  }
}

@media (max-width: 480px), (max-height: 580px) {
  .paragraph {
    font-size: 0.9rem;
  }
}
