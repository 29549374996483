.notifications {
  width: max-content;
  height: max-content;
  position: fixed;
  right: 1.5rem;
  bottom: 1.5rem;
  z-index: 1000;
}

@media (max-width: 1600px) {
  .notifications {
    left: 1.5rem;
    bottom: 1.5rem;
  }
}

@media (max-width: 550px) {
  .notifications {
    left: 1.5rem;
    top: 1.5rem;
  }
}
