.form {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.5rem;
  width: 768px;
  opacity: 1;

  transition: opacity 800ms linear, transform 800ms ease;

  > :nth-last-child(2) {
    margin-bottom: 2rem;
  }
}

.form-before {
  opacity: 0;
  transform: translateY(5rem);
}

.form-after {
  opacity: 1;
  transform: translateY(0);
}

.personal-info {
  display: flex;
  width: 100%;
  gap: 3rem;
}

@media (max-width: 1200px) {
  .form {
    width: 64%;
  }
}

@media (max-width: 768px) {
  .form {
    width: 85%;
  }
  .personal-info {
    flex-direction: column;
    gap: 0;
  }
}
