@use "../../partials/mixins" as m;

.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  @include m.section-dimensions;
  @include m.color-transition;
  overflow: hidden;
}

.contact-light {
  @include m.light-theme-template;
}

.contact-dark {
  @include m.dark-theme-template;
}

@media (max-height: 850px) {
  .contact {
    gap: 2rem;
  }
}
