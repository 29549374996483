@use "../../partials/variables" as v;

.typescript-logo-svg-dark {
  width: auto;
  .background {
    fill: v.$primary-color-dark-shade;
  }
  .text {
    fill: v.$secondary-color-two;
  }
}

.typescript-logo-svg-light {
  .background {
    fill: white;
  }
  .text {
    fill: v.$secondary-color-four;
  }
}

@media (max-width: 768px), (max-height: 850px) {
  .typescript-logo-svg {
    height: v.$skill-box-icon-size-small;
  }
}

@media (max-width: 600px) {
  .typescript-logo-svg {
    height: v.$skill-box-icon-size-smallest;
  }
}
