@use "../../partials/variables" as v;

.notification {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 270px;
  height: 50px;
  transform: translateX(150%);
  margin-top: -2rem;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
  font-family: "Roboto";
  font-size: 0.9rem;
  user-select: none;

  transition: transform 500ms ease, margin-top 300ms ease;
}

.notification-dark {
  background-color: v.$accent-color;
}

.notification-light {
  background-color: v.$secondary-color-four;
  color: white;
}

.notification-enter-active {
  transform: translateX(-4%);
  margin-top: 0.5rem;
}

.notification-enter-done {
  transform: translateX(0);
  margin-top: 0.5rem;
  transition: transform 200ms ease;
}

.notification-exit {
  transform: translateX(0%);
}

.notification-exit-active {
  transform: translateX(150%);
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  margin-left: 0.8rem;
}

@media (max-width: 1600px) {
  .notification {
    transform: translateX(-150%);
  }
  .notification-enter-active {
    transform: translateX(4%);
    margin-top: 0.5rem;
  }
  .notification-enter-done {
    transform: translateX(0);
  }
  .notification-exit {
    transform: translateX(0%);
  }
  .notification-exit-active {
    transform: translateX(-150%);
  }
}

@media (max-width: 550px) {
  .notification {
    margin-top: 0;
    transform: translateX(-150%);
  }
  .notification-enter-active {
    transform: translateX(4%);
    margin-top: 0.5rem;
  }
  .notification-enter-done {
    transform: translateX(0);
    margin-top: 0.5rem;
  }
  .notification-exit {
    margin-top: -50px;
    transform: translateX(0%);
  }
  .notification-exit-active {
    transform: translateX(-150%);
  }
}
