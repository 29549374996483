@use "sass:math";
@use "../../partials/variables" as v;

$panel-height: 40px;
$icon-size: $panel-height - 8px;
$switch-size: $panel-height - 6px;
$switch-margin: 4px;

$panel-height-small: 30px;
$icon-size-small: $panel-height-small - 6px;
$switch-size-small: $panel-height-small - 4px;
$switch-margin-small: 3px;

.panel {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80px;
  height: $panel-height;
  padding: 0 4px;
  margin: 0 30px;
  border: none;
  border-radius: ceil(math.div($panel-height, 2));
  font-size: $panel-height - 8px;
  outline: none;

  transition: opacity 800ms ease, transform 800ms ease;
}

.panel-before {
  opacity: 0;
  transform: translateX(5rem);
}

.panel-after {
  opacity: 1;
  transform: translateX(0);
}

.switch {
  position: absolute;
  top: 50%;
  left: $switch-margin;
  transform: translateY(-50%);
  height: $switch-size;
  width: $switch-size;
  border-radius: 50%;
  background-color: v.$primary-color-light;

  transition: left 0.55s ease;
}

.panel-light {
  background-color: v.$primary-color-dark-tint;
  color: v.$accent-color;
}

.panel-dark {
  background-color: v.$primary-color-dark-tint;
  color: v.$accent-color;
  .switch {
    left: calc(100% - $switch-size - $switch-margin);
  }
}

@media (max-width: 420px), (max-height: 850px) {
  .panel {
    height: $panel-height-small;
    width: 60px;
  }
  .switch {
    height: $switch-size-small;
    width: $switch-size-small;
    left: $switch-margin-small;
  }
  .panel-dark {
    .switch {
      left: calc(100% - $switch-size-small - $switch-margin-small);
    }
  }
}

.no-transition {
  transition: none;
}
