@use "../../partials/variables" as v;

$bullet-size: 10px;
$bullet-size-expanded: calc(#{$bullet-size} * 2);
$bullet-size-small: 6px;
$bullet-size-small-expanded: calc(#{$bullet-size-small} * 2);

.bullet {
  position: absolute;
  top: 50%;
  left: 50%;
  width: $bullet-size;
  height: $bullet-size;
  border: none;
  outline: none;
  transform: translate(-50%, -50%) rotate(-45deg);

  transition: transform 300ms ease, width 300ms ease, height 300ms ease;

  &:hover {
    cursor: pointer;
  }
}

.bullet-transition {
  transition: background-color v.$theme-toggle-transition-duration ease;
}

.bullet-before {
  transform: translate(calc(-50% + 100px), -50%) rotate(-45deg);
}

.bullet-dark {
  background-color: v.$secondary-color-two;
}

.bullet-light {
  background-color: v.$secondary-color-three;
}

.bullet-expanded {
  width: $bullet-size-expanded;
  height: $bullet-size-expanded;
  rotate: 0deg;
  background-color: transparent;
  transform: translate(-50%, -50%) rotate(0deg);

  transition: transform 300ms ease;
}

.bullet-expanded-transition {
  transition: border v.$theme-toggle-transition-duration ease;
}

.bullet-expanded-before {
  transform: translate(calc(-50% + 100px), -50%) rotate(0);
}

.bullet-expanded-dark {
  border: 2px solid v.$accent-color;
}

.bullet-expanded-light {
  border: 2px solid v.$secondary-color-four;
}

@media (max-width: 1200px) {
  .bullet {
    width: $bullet-size-small;
    height: $bullet-size-small;
  }

  .bullet-expanded {
    width: $bullet-size-small-expanded;
    height: $bullet-size-small-expanded;
    border-width: 1px;
  }
}
