@use "./partials/variables" as v;
@use "./partials/mixins" as m;

.app {
  position: relative;
  height: 100vh;
  overflow-y: hidden;
  scrollbar-width: none;
  font-family: "Mukta", sans-serif;
  // font-family: "Gochi Hand", "cursive";
  line-height: 1.2;
}

.app-dark {
  background-color: v.$primary-color-dark;

  ::selection {
    background-color: v.$primary-color-dark-shade;
  }

  @include m.color-transition;
}

.app-light {
  background-color: v.$primary-color-light;

  ::selection {
    background-color: v.$primary-color-light-tint-two;
  }

  @include m.color-transition;
}

.app-scrollable {
  overflow-y: scroll;
}
