.nav {
  visibility: visible;
  position: fixed;
  top: 50%;
  transform: translateY(-52.5%);
  right: 4%;
  z-index: 100;
}

.bullets {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.bullet-container {
  position: relative;
  width: 24px;
  height: 24px;
}

@media (max-width: 1200px) {
  .nav {
    right: 2%;
  }
  .bullets {
    gap: 0.5rem;
  }
  .bullet-container {
    width: 16px;
    height: 16px;
  }
}

@media (max-width: 768px) {
  .nav {
    visibility: hidden;
  }
}
