@use "../../partials/variables" as v;

.github-logo-svg {
  width: 100%;
  height: 100%;

  .path1 {
    stroke-width: 1.1px;
  }

  .path2 {
    stroke-width: 1.2px;
    stroke-dasharray: 135;
    stroke-dashoffset: 0;

    transition: stroke-dashoffset 400ms linear;
  }

  &:hover {
    .path2 {
      stroke-dashoffset: 135;
    }
  }
}

.github-logo-svg-dark {
  .path1 {
    stroke: v.$accent-color;
  }
  .path2 {
    stroke: v.$secondary-color-two;
  }
}

.github-logo-svg-light {
  .path1 {
    stroke: v.$secondary-color-four;
  }
  .path2 {
    stroke: v.$secondary-color-three;
  }
}
