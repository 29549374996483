@use "../../partials/variables" as v;

.heading {
  font-size: 4rem;
  text-align: center;
  opacity: 1;

  z-index: 100;

  transition: opacity 800ms linear,
    color v.$theme-toggle-transition-duration ease;
}

.heading-dark {
  color: v.$secondary-color;
}

.heading-light {
  color: v.$secondary-color-three;
}

.heading-before {
  opacity: 0;
}

.heading-after {
  opacity: 1;
}

@media (max-width: 1200px) {
  .heading {
    font-size: 3.5rem;
  }
}

@media (max-width: 768px), (max-height: 850px) {
  .heading {
    width: 85%;
    font-size: 2.5rem;
  }
}
