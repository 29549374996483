@use "../../partials/variables" as v;

.loading-screen {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: v.$primary-color-light;

  font-size: 5rem;
}

.logo-letter {
  position: absolute;
  color: v.$secondary-color-three;
  font-family: serif;
  top: 50%;
  transform: translateY(-32%);
  opacity: 0;
  user-select: none;

  animation: fadeIn 800ms ease forwards;
  animation-delay: 2350ms;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .loading-screen {
    font-size: 2.4rem;
  }
}
