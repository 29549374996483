@use "../../partials/variables" as v;

.skill-box {
  display: flex;
  align-items: center;
  gap: 0.7rem;
  height: 52px;
  padding: 0 14px;
  border-radius: 10px;
  font-family: "Roboto";
  font-size: 1.2rem;
  user-select: none;

  transition: opacity 800ms linear, transform 800ms ease;
}

.skill-box-light {
  background-color: v.$secondary-color-four;
  color: white;
}

.skill-box-dark {
  background-color: v.$secondary-color-two;
  color: v.$primary-color-dark-shade;
}

.skill-box-before {
  opacity: 0;
  transform: translateY(5rem);
}

.skill-box-after {
  opacity: 1;
  transform: translateY(0);
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: v.$skill-box-icon-size;
}

@media (max-width: 768px), (max-height: 850px) {
  .icon {
    font-size: v.$skill-box-icon-size-small;
  }
  .skill-box {
    height: 40px;
    font-size: 1rem;
  }
}

@media (max-width: 600px), (max-height: 600px) {
  .icon {
    font-size: v.$skill-box-icon-size-smallest;
  }
  .skill-box {
    height: 30px;
    padding: 0 9px;
    gap: 0.5rem;
    border-radius: 8px;
    font-size: 0.9rem;
  }
}
