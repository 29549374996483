// colors
$primary-color-dark-shade: #16161a;
$primary-color-dark: #25252d;
$primary-color-dark-tint: #34343f;
$primary-color-medium-shade: #434351;
$primary-color-medium: #6f6f89;
$primary-color-light-shade: #83838c;
$primary-color-light: #dadae0;
$primary-color-light-tint: #e6eaf2;
$primary-color-light-tint-two: #f2f2f3;
$secondary-color: #5fe88d;
$secondary-color-two: #c27ba0;
$secondary-color-three: #b71c1c;
$secondary-color-four: #008cba;
$accent-color: #fff44f;

// sizes
$skill-box-icon-size: 2rem;
$skill-box-icon-size-small: 1.5rem;
$skill-box-icon-size-smallest: 1.2rem;

// durations
$theme-toggle-transition-duration: 550ms;
