@use "./variables" as v;

@mixin section-dimensions {
  width: 100%;
  height: 100vh;
}

@mixin dark-theme-template {
  background-color: v.$primary-color-dark;
  color: v.$secondary-color;
}

@mixin light-theme-template {
  background-color: v.$primary-color-light;
  color: v.$secondary-color-three;
}

@mixin color-transition {
  transition: background-color v.$theme-toggle-transition-duration ease,
    color v.$theme-toggle-transition-duration ease;
}
