@use "../../partials/variables" as v;

.underline {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 0;

  transition: width 300ms ease;
}

.underline-valid {
  width: 100%;
}

.underline-valid-dark {
  background-color: v.$secondary-color-two;
}

.underline-valid-light {
  background-color: v.$secondary-color-four;
}

.underline-invalid {
  width: 100%;
  background-color: red;
}
